import Cookies from 'universal-cookie';

const { localStorage } = window;
const cookies = new Cookies();

export function setStoreCategory(data) {
  localStorage.setItem('storeCategory', JSON.stringify(data));
}

export function getStoreCategory() {
  const categories = localStorage.getItem('storeCategory');
  return (
    (categories === 'undefined' || categories === 'null')
      ? null : JSON.parse(categories)
  );
}

export function setProductCategories(data) {
  localStorage.setItem('productCategories', JSON.stringify(data));
}

export function getProductCategories() {
  const categories = localStorage.getItem('productCategories');
  return (
    (categories === 'undefined' || categories === 'null')
      ? null : JSON.parse(categories)
  );
}

export function setRegesteredRegions(data) {
  localStorage.setItem('regesteredRegions', JSON.stringify(data));
}

export function getRegesteredRegions() {
  const regions = localStorage.getItem('regesteredRegions');
  return (
    (regions === 'undefined' || regions === 'null')
      ? null : JSON.parse(regions)
  );
}

export function setToken(data) {
  return cookies.set('token', data);
}

export function removeToken() {
  return cookies.remove('token');
}

export function getToken() {
  const token = cookies.get('token');
  return (
    token === 'undefined'
    || token === 'null'
    || token === ''
      ? null : token
  );
}

export function setFCMToken(data) {
  return cookies.set('fcmToken', data);
}

export function removeFCMToken() {
  return cookies.remove('fcmToken');
}

export function setUserName(data) {
  return cookies.set('userName', data);
}

export function removeUserName() {
  return cookies.remove('userName');
}

export function setUserNumber(data) {
  return cookies.set('userNumber', data);
}

export function removeUserNumber() {
  return cookies.remove('userNumber');
}

export function getLoginStep() {
  const loginStep = cookies.get('loginStep');
  return (
    loginStep === 'undefined'
    || loginStep === 'null'
    || loginStep === ''
      ? null : loginStep
  );
}

export function setLoginStep(loginStep) {
  cookies.set('loginStep', loginStep);
}

export function removeLoginStep() {
  return cookies.remove('loginStep');
}

export function getUserName() {
  const userName = cookies.get('userName');
  return (
    userName === 'undefined'
    || userName === 'null'
    || userName === ''
      ? null : userName
  );
}

export function getUserNumber() {
  const userNumber = cookies.get('userNumber');
  return (
    userNumber === 'undefined'
    || userNumber === 'null'
    || userNumber === ''
      ? null : userNumber
  );
}

export function setUserSession(session) {
  cookies.set('userSession', session);
}

export function removeUserSession() {
  return cookies.remove('userSession');
}

export function getUserSession() {
  return cookies.get('userSession');
}

export function setUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function removeUser() {
  localStorage.removeItem('user');
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function setUserPermissions(data) {
  localStorage.setItem('userPermissions', JSON.stringify(data));
}

export function getUserPermissions() {
  return JSON.parse(localStorage.getItem('userPermissions'));
}

export function removeUserPermission() {
  localStorage.removeItem('userPermissions');
}

export function removeUserGroup() {
  return localStorage.removeItem('userGroup');
}

export function getFeedsCount(storeCode) {
  const feedsCount = JSON.parse(localStorage.getItem('feeds'));
  if (feedsCount && feedsCount[storeCode || 'all']) {
    return feedsCount[storeCode || 'all'];
  }
  return 0;
}

export function setFeedsCount(storeCode, count) {
  const feedsCount = JSON.parse(localStorage.getItem('feeds')) || {};
  feedsCount[storeCode || 'all'] = count;
  localStorage.setItem('feeds', JSON.stringify(feedsCount));
}

export function setLastQueryTypeQueried(queryType) {
  localStorage.setItem('queryType', queryType);
}

export function getLastQueryTypeQueried() {
  return localStorage.getItem('queryType');
}

export function setLeadsSchema(data) {
  localStorage.setItem('leadSchema', JSON.stringify(data));
}

export function getLeadsSchema() {
  const schema = localStorage.getItem('leadSchema');
  return (
    (schema === 'undefined' || schema === 'null')
      ? null : JSON.parse(schema)
  );
}

export function setAgentsList(data) {
  localStorage.setItem('agentList', JSON.stringify(data));
}

export function getAgentsList() {
  const agents = localStorage.getItem('agentList');
  return (
    (agents === 'undefined' || agents === 'null')
      ? null : JSON.parse(agents)
  );
}

export function removeLeadsSchema() {
  return localStorage.removeItem('leadSchema');
}

export function setFraudAgents(data) {
  localStorage.setItem('fraudAgents', JSON.stringify(data));
}

export function getFraudAgents() {
  const agents = localStorage.getItem('fraudAgents');
  return (
    (agents === 'undefined' || agents === 'null')
      ? null : JSON.parse(agents)
  );
}

export function getLastLoggedInAtEpoch() {
  return JSON.parse(localStorage.getItem('lastLoggedInAtEpoch'));
}

export function setLastLoggedInAtEpoch(lastLoggedInAtEpoch) {
  localStorage.setItem('lastLoggedInAtEpoch', JSON.stringify(lastLoggedInAtEpoch));
}
