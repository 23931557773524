import React from 'react';
import PropTypes from 'prop-types';
import Prev from './svg/Prev';
import Next from './svg/Next';
import Search from './svg/Search';
import Close from './svg/Close';
import CircleClose from './svg/CircleClose';
import Arrow from './svg/Arrow';
import File from './svg/File';
import FileHand from './svg/FileHand';
import Calendar from './svg/Calendar';
import Eye from './svg/Eye';
import Retry from './svg/Retry';
import Copy from './svg/Copy';
import CircleDone from './svg/CircleDone';
import New from './svg/New';
import Processing from './svg/Processing';
import CircleArrowHead from './svg/CircleArrowHead';
import ArrowHead from './svg/ArrowHead';
import CheckedIcon from './svg/CheckedIcon';
import Trash from './svg/Trash';
import Delivery from './svg/Delivery';
import SupportPhone from './svg/SupportPhone';
import TakeAway from './svg/TakeAway';
import Camera from './svg/Camera';
import LoveLocalIcon from './svg/LoveLocalIcon';
import GooglePlayIcon from './svg/GooglePlayIcon';
import EditIcon from './svg/EditIcon';
import Call from './svg/Call';
import Sms from './svg/Sms';
import Add from './svg/Add';
import Download from './svg/Download';
import Chat from './svg/Chat';
import WarningTriangle from './svg/WarningTriangle';
import WarningCircle from './svg/WarningCircle';
import Banned from './svg/Banned';
import CircleAdd from './svg/CircleAdd';
import WhatsApp from './svg/WhatsApp';
import Premium from './svg/Premium';

const Svg = (props) => {
  const {
    svg,
  } = props;
  switch (svg) {
    case 'prev': return <Prev {...props} />;
    case 'next': return <Next {...props} />;
    case 'search': return <Search {...props} />;
    case 'circleClose': return <CircleClose {...props} />;
    case 'close': return <Close {...props} />;
    case 'arrow': return <Arrow {...props} />;
    case 'file': return <File {...props} />;
    case 'fileHand': return <FileHand {...props} />;
    case 'calendar': return <Calendar {...props} />;
    case 'eye': return <Eye {...props} />;
    case 'retry': return <Retry {...props} />;
    case 'copy': return <Copy {...props} />;
    case 'circleDone': return <CircleDone {...props} />;
    case 'new': return <New {...props} />;
    case 'processing': return <Processing {...props} />;
    case 'circleArrowHead': return <CircleArrowHead {...props} />;
    case 'arrowHead': return <ArrowHead {...props} />;
    case 'checkedIcon': return <CheckedIcon {...props} />;
    case 'trash': return <Trash {...props} />;
    case 'delivery': return <Delivery {...props} />;
    case 'supportPhone': return <SupportPhone {...props} />;
    case 'takeAway': return <TakeAway {...props} />;
    case 'camera': return <Camera {...props} />;
    case 'lovelocalIcon': return <LoveLocalIcon {...props} />;
    case 'googlePlayIcon': return <GooglePlayIcon {...props} />;
    case 'editIcon': return <EditIcon {...props} />;
    case 'call': return <Call {...props} />;
    case 'sms': return <Sms {...props} />;
    case 'add': return <Add {...props} />;
    case 'download': return <Download {...props} />;
    case 'chat': return <Chat {...props} />;
    case 'warningTriangle': return <WarningTriangle {...props} />;
    case 'warningCircle': return <WarningCircle {...props} />;
    case 'banned': return <Banned {...props} />;
    case 'circleAdd': return <CircleAdd {...props} />;
    case 'whatsapp': return <WhatsApp {...props} />;
    case 'premiumTag': return <Premium {...props} />;
    default: return null;
  }
};

Svg.propTypes = {
  svg: PropTypes.string.isRequired,
};

export default Svg;
