import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Button,
} from 'react-bootstrap';
import Select from 'react-select';
import { CustomModal } from '../../../../component/common';
import { uploadImage } from '../../../../assets/api/axios';
import brandBannersApis from '../../apis';

const customerBannerTypes = [{
  value: 'GUESTS',
  label: 'GUESTS',
},
{
  value: '0_ORDER',
  label: '0_ORDER',
},
{
  value: 'RETURNING',
  label: 'RETURNING',
}];
const bannerLocation = ['BANNER_BOTTOM', 'BANNER_MIDDLE', 'BANNER_TOP'];
const languages = ['ALL', 'en', 'gu', 'hi', 'kn', 'mr', 'te'];
const bannerTypes = ['HOME_PAGE', 'B2C_TOPMOST', 'BRAND_BANNER', 'REFER', 'SHOPS_TAB', 'GROWTH'];

function PromotionalBannerCnE(props) {
  const {
    selectedBannerData, submitType,
    showEditModal, onClose, afterPatchAndPostSyncLocal,
  } = props;

  const promotionalBanner = {
    title: '',
    // text: '',
    bannerType: 'BRAND_BANNER',
    customerBannerTypes: ['0_ORDER', 'GUESTS', 'RETURNING'],
    brandImage: '',
    brandWebImage: '',
    largeImage: '',
    alt: '',
    appDeepLink: '',
    webDeepLink: '',
    priorityOrder: 1,
    language: 'ALL',
    isPublic: true,
    videoLink: '',
    bannerLocation: 'BANNER_TOP',
    pinCode: '',
    radius: '',
    iosImage: '',
    iosDeepLink: '',
    storeCodes: '',
    bannersType: 'PROMOTIONAL_BANNER',
    launchDate: new Date().toISOString().split('T')[0],
    expiryDate: new Date().toISOString().split('T')[0],
  };

  const [editData, setEditData] = useState(promotionalBanner);
  const [submitStatus, setSubmitStatus] = useState('');

  const isButtonDisabled = (
    !editData.title
    // || !editData.text
    || !editData.brandImage
    || !editData.brandWebImage
    || !editData.appDeepLink
    || !editData.webDeepLink
    || !editData.priorityOrder
    // || !editData.videoLink
    || !editData.iosImage
    || !editData.iosDeepLink
    || submitStatus === 'loading'
  );

  useEffect(() => {
    if (submitType === 'EDIT') {
      setEditData({
        ...editData,
        ...selectedBannerData,
      });
    } else {
      setEditData({
        ...promotionalBanner,
      });
    }
  }, [selectedBannerData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleImageChange = (name, file) => {
    const formData = new FormData();
    formData.append('img', file);
    if (file) {
      uploadImage(
        'post',
        formData,
      ).then((res) => {
        setEditData({
          ...editData,
          [name]: res.data.url,
        });
      }).catch(() => {
      });
    }
  };

  const submitBrandSpace = () => {
    setSubmitStatus('loading');
    const bannerSubmitApi = submitType === 'EDIT' ? brandBannersApis.bannersPatch : brandBannersApis.bannersPost;
    bannerSubmitApi(
      {},
      {
        ...editData,
        launchDate: `${new Date(editData.launchDate).getTime()}`,
        expiryDate: `${new Date(editData.expiryDate).getTime()}`,
        pinCode: editData.pinCode || 0,
        radius: editData.radius || 0,
      },
    ).then((res) => {
      afterPatchAndPostSyncLocal(res.data.data, submitType);
      setSubmitStatus('success');
      onClose();
    }).catch(() => {
      setSubmitStatus('error');
    });
  };

  return (
    <CustomModal
      show={!!showEditModal}
      size="lg"
      onHide={() => {
        onClose();
      }}
      body={(
        <Container
          className="p-4 border-radius-16"
        >
          <Row>
            {/* Banner Title */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Title
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.title}
                  name="title"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Text */}
            {/* <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Text
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.text}
                  name="text"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col> */}

            {/* Banner Priority Order */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Priority Order
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.priorityOrder}
                  name="priorityOrder"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner alt */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Alt (Optional)
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.alt}
                  name="alt"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Pincode */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Pincode (Optional)
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.pinCode}
                  name="pinCode"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Radius */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Radius [in meters] (Optional)
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.radius}
                  name="radius"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner App DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner App DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.appDeepLink}
                  name="appDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner iOS DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner iOS DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.iosDeepLink}
                  name="iosDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Web DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Web DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.webDeepLink}
                  name="webDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Launch Date */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Launch Date
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="date"
                  value={editData.launchDate}
                  name="launchDate"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner expiryDate */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Expiry Date
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="date"
                  value={editData.expiryDate}
                  name="expiryDate"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner videoLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Video Link (Optional)
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.videoLink}
                  name="videoLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Location */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Location
              </div>
              <div
                className="w-100"
              >
                <select
                  className="form-control"
                  value={editData.bannerLocation}
                  name="bannerLocation"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  {
                    bannerLocation.map((item) => (
                      <option
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    ))
                  }
                </select>
              </div>
            </Col>

            {/* Banner Languages */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Language
              </div>
              <div
                className="w-100"
              >
                <select
                  className="form-control"
                  value={editData.language}
                  name="language"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  {
                    languages.map((item) => (
                      <option
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    ))
                  }
                </select>
              </div>
            </Col>

            {/* Banner Type */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Type
              </div>
              <div
                className="w-100"
              >
                <select
                  className="form-control"
                  value={editData.bannerType}
                  name="bannerType"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  {
                    bannerTypes.map((item) => (
                      <option
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    ))
                  }
                </select>
              </div>
            </Col>

            {/* Banner Store Ids */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Store Id&apos;s (Comma separated):
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.storeCodes}
                  placeholder="Enter Store Ids - In Comma Separated (e.g 12345,54321)"
                  name="storeCodes"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Customer Banner Types */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Customer Banner Types
              </div>
              <div
                className="w-100"
              >
                <Select
                  id="customerBannerTypes"
                  onChange={(selectedTypes) => {
                    const values = selectedTypes && selectedTypes.map((item) => item.value);
                    const event = {
                      target: {
                        name: 'customerBannerTypes',
                        value: values || '',
                      },
                    };
                    handleChange(event);
                  }}
                  value={editData.customerBannerTypes.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  isMulti
                  options={[
                    ...customerBannerTypes,
                  ]}
                />
              </div>
            </Col>

            {/* Banner Images */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <Row>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    Android Banner Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('brandImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.brandImage && (
                      <div>
                        <img src={editData.brandImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    iOS Banner Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('iosImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.iosImage && (
                      <div>
                        <img src={editData.iosImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    Web Banner Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('brandWebImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.brandWebImage && (
                      <div>
                        <img src={editData.brandWebImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
              </Row>
            </Col>

            {/* Submit Banner */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <Button
                variant="primary"
                block
                className="font-weight-bold"
                onClick={() => {
                  submitBrandSpace();
                }}
                disabled={isButtonDisabled}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
}

PromotionalBannerCnE.defaultProps = {
  submitType: 'ADD',
  selectedBannerData: {},
  showEditModal: false,
  onClose: () => {},
  afterPatchAndPostSyncLocal: () => {},
};

PromotionalBannerCnE.propTypes = {
  submitType: PropTypes.string,
  selectedBannerData: PropTypes.shape({}),
  showEditModal: PropTypes.bool,
  onClose: PropTypes.func,
  afterPatchAndPostSyncLocal: PropTypes.func,
};

export default PromotionalBannerCnE;
