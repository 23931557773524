import React from 'react';

const Scooter = () => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.833 5.833c0-.916-.75-1.666-1.666-1.666H12.5a.836.836 0 0 0-.833.833c0 .458.375.833.833.833h1.667v2.209l-2.9 3.625H8.333V8.333A.836.836 0 0 0 7.5 7.5H5a3.332 3.332 0 0 0-3.333 3.333V12.5c0 .458.375.833.833.833h.833c0 1.384 1.117 2.5 2.5 2.5 1.384 0 2.5-1.116 2.5-2.5h2.934c.508 0 .983-.233 1.3-.625l2.9-3.625c.241-.3.366-.666.366-1.041V5.833Zm-10 8.334A.836.836 0 0 1 5 13.333h1.667a.836.836 0 0 1-.834.834Z"
      fill="#788898"
    />
    <path
      d="M5 5h2.5c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834H5a.836.836 0 0 1-.833-.834C4.167 5.375 4.542 5 5 5Zm10.833 5.833a2.497 2.497 0 0 0-2.5 2.5c0 1.384 1.117 2.5 2.5 2.5 1.384 0 2.5-1.116 2.5-2.5 0-1.383-1.116-2.5-2.5-2.5Zm0 3.334a.836.836 0 0 1-.833-.834c0-.458.375-.833.833-.833.459 0 .834.375.834.833a.836.836 0 0 1-.834.834Z"
      fill="#788898"
    />
  </svg>
);

export default Scooter;
