import { Storage } from '../utilities';
import Priviledge from './priviledge';

const {
  RETAILER,
  RETAILER_DETAILS,
  ORDER_DETAILS,
  DELIVERY_SETTING,
  SIGNIN,
  DASHBOARD,
  SHOP_ALLOCATION,
  ORDERS,
  PLACE_ORDER,
  CREATE_OFFER,
  BULK_COUPON_CREATION,
  SHOP_REVIEW,
  CUSTOMERS_REVIEW,
  REVIEW_PANEL,
  LL_DAILY,
  BANNERS,
  SHARE_OFFER_CARD,
  SMS_SENT,
  PENDING_SETTLEMENTS,
  RECONCILED_SETTLEMENTS,
  PENDING_SETTLEMENTS_STORE,
  FEEDS,
  GENERIC_BULK_UPLOAD,
  COUPONS,
  COUPON_TRACKER,
  QUERY_TRACKER,
  COUPON_DETAILS,
  REFERRAL_CHECK,
  PRICE_UPDATE,
  PRODUCT_ADD,
  STORE_WISE_JOB_STATUS,
  SHOP_LOGO_REVIEW,
  FLASH_SALE_DETAILED,
  FLASH_SALE_AGGREGATED,
  STORIES,
  CUSTOMER,
  PRODUCT_BULK_UPLOAD,
  RETAILER_STORY_REVIEW,
  REFUND_DASHBOARD,
  SMART_CATALOGUES,
  SMART_CATALOGUES_STORE,
  SMART_CATALOGUE_MAPPINGS,
  LIBRARY_PRODUCT_REGIONAL_PRICE,
  LIBRARY_PRODUCTS,
  BACKGROUND_JOBS,
  LEAD_MANAGEMENT,
  EXPORT_CLEANUP_TOOL,
  SHOP_CATALOGUE_PAGE,
  EXPORT_CATALOG_PRODUCTS,
  CUSTOMER_PANEL,
  FRAUD_MANAGEMENT,
  OM_STATUS_TRACKER,
  RETAILER_ORDER_DETAILS,
  STORE_RESURRECTION,
  LEADERSHIP_BOARD,
  CAMPAIGN_ANALYTICS,
  CART_ABANDON_USER,
  CART_ABANDON_DETAILS,
  COUPON_ENGINE,
  CAMPAIGN_DETAILS,
  RETAILER_CATALOGUE_FILES,
  CX_RX_CALL_MAPPING,
  BRAND_SAMPLING_CAMPAIGN,
  BRAND_SAMPLING_CAMPAIGN_DETAILS,
  MARKETPLACE_BRANDS,
  MARKETPLACE_ORDERS,
  MARKETPLACE_BRAND_DETAILS,
  MARKETPLACE_ORDER_DETAILS,
  MARKETPLACE_BRAND_PRODUCTS,
  BRAND_BANNERS,
  FESTIVE_CATEGORIES,
  FINANCE_SETTLEMENT_DASHBOARD,
  PLATFORM_FEE_DASHBOARD,
} = Priviledge;

const permissionPriviledges = {
  CREATE_OFFER_READ: [
    CREATE_OFFER,
  ],
  SHOP_ALLOCATION_READ: [
    SHOP_ALLOCATION,
  ],
  RETAILER_READ: [
    RETAILER,
    RETAILER_DETAILS,
    RETAILER_ORDER_DETAILS,
  ],
  RETAILER_SETTING_READ: [
    DELIVERY_SETTING,
  ],
  PLACE_ORDER_READ: [
    PLACE_ORDER,
  ],
  COUPON_CREATION_READ: [
    BULK_COUPON_CREATION,
  ],
  REVIEW_PANEL_READ: [
    REVIEW_PANEL,
  ],
  STORES_REVIEW_READ: [
    SHOP_REVIEW,
  ],
  LOVELOCAL_DAILY_READ: [
    LL_DAILY,
  ],
  SHARE_OFFER_CARD_READ: [
    SHARE_OFFER_CARD,
  ],
  SMS_SENT_READ: [
    SMS_SENT,
  ],
  BANNERS_READ: [
    BANNERS,
  ],
  CUSTOMERS_REVIEW_READ: [
    CUSTOMERS_REVIEW,
  ],
  PENDING_SETTLEMENTS_READ: [
    PENDING_SETTLEMENTS,
  ],
  RECONCILED_SETTLEMENTS_READ: [
    RECONCILED_SETTLEMENTS,
  ],
  PENDING_SETTLEMENTS_STORE_READ: [
    PENDING_SETTLEMENTS_STORE,
  ],
  FEEDS_SECTION_READ: [
    FEEDS,
  ],
  GENERIC_BULK_UPLOAD_READ: [
    GENERIC_BULK_UPLOAD,
  ],
  COUPON_READ: [
    COUPONS,
    COUPON_DETAILS,
  ],
  COUPON_TRACKER_READ: [
    COUPON_TRACKER,
  ],
  QUERY_TRACKER_READ: [
    QUERY_TRACKER,
  ],
  REFERRAL_CHECK_READ: [
    REFERRAL_CHECK,
  ],
  SIGNIN_READ: [
    SIGNIN,
  ],
  PRODUCT_BULK_UPLOAD_READ: [
    PRODUCT_BULK_UPLOAD,
  ],
  PRICE_UPDATE_READ: [
    PRICE_UPDATE,
  ],
  PRODUCT_ADD_READ: [
    PRODUCT_ADD,
  ],
  STORE_WISE_JOB_STATUS_READ: [
    STORE_WISE_JOB_STATUS,
  ],
  SHOP_LOGO_REVIEW_READ: [
    SHOP_LOGO_REVIEW,
  ],
  RETAILER_STORY_REVIEW_READ: [
    RETAILER_STORY_REVIEW,
  ],
  FLASH_SALE_DETAILED_READ: [
    FLASH_SALE_DETAILED,
  ],
  FLASH_SALE_AGGREGATED_READ: [
    FLASH_SALE_AGGREGATED,
  ],
  STORIES_READ: [
    STORIES,
  ],
  CUSTOMER_READ: [
    CUSTOMER,
  ],
  REFUND_DASHBOARD_READ: [
    REFUND_DASHBOARD,
  ],
  SMART_CATALOGUES_READ: [
    SMART_CATALOGUES,
  ],
  SMART_CATALOGUES_STORE_READ: [
    SMART_CATALOGUES_STORE,
  ],
  SMART_CATALOGUE_MAP_READ: [
    SMART_CATALOGUE_MAPPINGS,
  ],
  LIBRARY_PRODUCT_REGIONAL_PRICE_READ: [
    LIBRARY_PRODUCT_REGIONAL_PRICE,
  ],
  LIBRARY_PRODUCTS_READ: [
    LIBRARY_PRODUCTS,
    RETAILER_CATALOGUE_FILES,
  ],
  BACKGROUND_JOBS_READ: [
    BACKGROUND_JOBS,
  ],
  CUSTOMER_PANEL_READ: [
    CUSTOMER_PANEL,
  ],
  FRAUD_MANAGEMENT_READ: [
    FRAUD_MANAGEMENT,
  ],
  LEAD_MANAGEMENT_ADMIN: [
    LEAD_MANAGEMENT,
  ],
  LEAD_MANAGEMENT_READ: [
    LEAD_MANAGEMENT,
  ],
  EXPORT_CLEANUP_TOOL_READ: [
    EXPORT_CLEANUP_TOOL,
    SHOP_CATALOGUE_PAGE,
  ],
  SHOP_CATALOGUE_PAGE_READ: [
    SHOP_CATALOGUE_PAGE,
  ],
  EXPORT_CATALOG_PRODUCTS_READ: [
    EXPORT_CATALOG_PRODUCTS,
  ],
  ORDERS_READ: [
    ORDERS,
    ORDER_DETAILS,
  ],
  OM_STATUS_TRACKER_READ: [
    OM_STATUS_TRACKER,
  ],
  STORE_RESURRECTION_WRITE: [
    STORE_RESURRECTION,
  ],
  LEADERSHIP_BOARD_READ: [
    LEADERSHIP_BOARD,
  ],
  CAMPAIGN_ANALYTICS_READ: [
    CAMPAIGN_ANALYTICS,
  ],
  CART_ABANDON_USER_WRITE: [
    CART_ABANDON_USER,
    CART_ABANDON_DETAILS,
  ],
  COUPON_ENGINE_READ: [
    COUPON_ENGINE,
    CAMPAIGN_DETAILS,
  ],
  CX_RX_CALL_MAPPING_READ: [
    CX_RX_CALL_MAPPING,
  ],
  BRAND_SAMPLING_CAMPAIGN_READ: [
    BRAND_SAMPLING_CAMPAIGN,
    BRAND_SAMPLING_CAMPAIGN_DETAILS,
  ],
  MARKETPLACE_READ: [
    MARKETPLACE_BRANDS,
    MARKETPLACE_ORDERS,
    MARKETPLACE_BRAND_DETAILS,
    MARKETPLACE_ORDER_DETAILS,
    MARKETPLACE_BRAND_PRODUCTS,
  ],
  MARKETPLACE_WRITE: [
    MARKETPLACE_BRANDS,
    MARKETPLACE_ORDERS,
    MARKETPLACE_BRAND_DETAILS,
    MARKETPLACE_ORDER_DETAILS,
    MARKETPLACE_BRAND_PRODUCTS,
  ],

  BRAND_BANNERS_READ: [
    BRAND_BANNERS,
  ],
  BRAND_BANNERS_WRITE: [
    BRAND_BANNERS,
  ],

  FESTIVE_CATEGORY_READ: [
    FESTIVE_CATEGORIES,
  ],

  FINANCE_SETTLEMENT_DASHBOARD_READ: [
    FINANCE_SETTLEMENT_DASHBOARD,
  ],
  FINANCE_SETTLEMENT_DASHBOARD_WRITE: [
    FINANCE_SETTLEMENT_DASHBOARD,
  ],

  PLATFORM_FEE_DASHBOARD_READ: [
    PLATFORM_FEE_DASHBOARD,
  ],
  PLATFORM_FEE_DASHBOARD_WRITE: [
    PLATFORM_FEE_DASHBOARD,
  ],

  // newly created routes will go here for dev purpose after
  // dev every route should have authorized specific permission
  DEV_READ_READ: [],
  DEV_READ_WRITE: [],
};

function getUserAccess() {
  const userPermissionSet = Storage.getUserPermissions();
  const userPriviledge = [
    SIGNIN,
    DASHBOARD,
  ];
  if (userPermissionSet) {
    userPermissionSet.forEach((item) => {
      if (permissionPriviledges[item]) {
        permissionPriviledges[item].forEach((element) => {
          if (!userPriviledge.includes(element)) {
            userPriviledge.push(element);
          }
        });
      }
    });
  }
  return {
    priviledge: userPriviledge,
    permission: userPermissionSet,
  };
}

export default getUserAccess;
