import React from 'react';

const ShopIcon = () => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.475 2.802V.56a.56.56 0 0 0-.56-.56H1.085a.56.56 0 0 0-.56.56v2.242A.56.56 0 0 0 0 3.36v2.305a3.098 3.098 0 0 0 .525 1.737v6.038c0 .308.25.56.56.56h11.83c.31 0 .56-.25.56-.56V7.402A3.127 3.127 0 0 0 14 5.664V3.36a.56.56 0 0 0-.525-.558ZM1.785 1.26h10.43V2.8H1.785V1.26ZM8.12 12.742H5.88V10.92h2.24v1.822Zm4.095 0H9.24V10.36a.56.56 0 0 0-.56-.56H5.32a.56.56 0 0 0-.56.56v2.382H1.785V8.503a3.154 3.154 0 0 0 1.39.317 3.16 3.16 0 0 0 1.909-.634.011.011 0 0 1 .01 0c.21.157.437.287.678.387.39.165.805.247 1.232.247a3.16 3.16 0 0 0 1.909-.634.011.011 0 0 1 .01 0c.21.157.437.287.678.387.39.165.804.247 1.231.247a3.16 3.16 0 0 0 1.39-.317v4.239h-.007Zm.525-7.077a1.905 1.905 0 0 1-1.913 1.895 1.917 1.917 0 0 1-1.666-.96.28.28 0 0 0-.243-.143h-.01c-.1 0-.193.055-.244.142A1.912 1.912 0 0 1 7 7.56a1.914 1.914 0 0 1-1.662-.957.289.289 0 0 0-.5 0 1.919 1.919 0 0 1-1.665.957A1.905 1.905 0 0 1 1.26 5.665V4.069c0-.005.004-.009.009-.009H12.73c.005 0 .009.004.009.009v1.596Z"
      fill="#788898"
    />
  </svg>
);

export default ShopIcon;
