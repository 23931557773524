import Axios from 'axios';
import React, { Component } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { ErrorHandler, Svg } from '../../component/common';
import brandBannersApis from './apis';
import BrandSpace from './components/banner-listing/BrandSpace';
import LoveLocalBanner from './components/banner-listing/LoveLocalBanner';
import OtherLoveLocalBanner from './components/banner-listing/OtherLoveLocalBanner';
import PromotionalBanner from './components/banner-listing/PromotionalBanner';
import BrandSpaceCnE from './components/add-and-edit-banners/BrandSpaceCnE';
import LoveLocalBannerCnE from './components/add-and-edit-banners/LoveLocalBannerCnE';
import PromotionalBannerCnE from './components/add-and-edit-banners/PromotionalBannerCnE';
import OtherLoveLocalBannerCnE from './components/add-and-edit-banners/OtherLoveLocalBannerCnE';
import MainBannerCnE from './components/add-and-edit-banners/MainBannerCnE';

const bannerTypes = [
  'BRAND_SPACE',
  'LL_BANNER',
  'PROMOTIONAL_BANNER',
  'OTHER_LL_BANNER',
  'MAIN_BANNER',
];

const { CancelToken } = Axios;
let axiosController = null;

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    t,
  } = param;
  t = t || 'BRAND_SPACE';
  return ({
    ...param, t,
  });
};

class BrandBanners extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      banners: null,
      loading: true,
      error: false,
      bannerType: param.t,
      addEditBannerType: '',
      selectedBannerData: {},
      submitType: 'ADD',
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { bannerType, banners } = this.state;
    if (axiosController) {
      axiosController.cancel();
    }
    axiosController = CancelToken.source();

    const nextUrl = banners ? banners.next : '';
    this.setState({
      loading: true,
      error: false,
    });

    brandBannersApis.bannersGet(
      {
        bannerType,
        ...!banners ? { page: 1 } : { },
      },
      nextUrl,
    ).then((res) => {
      axiosController = null;
      this.setState({
        banners: nextUrl ? {
          ...banners,
          ...res.data,
          data: [
            ...banners.data,
            ...res.data.data,
          ],
        } : res.data,
        loading: false,
        error: false,
      });
    }).catch((err) => {
      if (Axios.isCancel(err)) {
        return;
      }
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleLazyLoad = (event) => {
    const { banners, loading } = this.state;
    if (loading) return;
    const { scrollHeight, scrollTop, offsetHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 100
      || scrollHeight === scrollTop + offsetHeight) {
      if (banners.count && banners.next) {
        this.handleLoad();
      }
    }
  }

  onClickEdit = (
    addEditBannerType, selectedBannerData,
  ) => {
    this.setState({
      addEditBannerType,
      selectedBannerData,
      submitType: 'EDIT',
    });
  }

  afterPatchAndPostSyncLocal = (
    bannerDetails, updateType,
  ) => {
    const { banners } = this.state;
    if (updateType === 'EDIT') {
      this.setState({
        banners: {
          ...banners,
          data: banners.data.map((item) => (
            (item.bannerId).toString() === (bannerDetails.bannerId).toString() ? ({
              ...item,
              ...bannerDetails,
            }) : (item)
          )),
        },
      });
    } else {
      this.setState({
        banners: {
          ...banners,
          data: [
            bannerDetails,
            ...banners.data,
          ],
        },
      });
    }
  }

  render() {
    const {
      banners, loading, error, bannerType,
      addEditBannerType, selectedBannerData,
      submitType,
    } = this.state;
    const { history } = this.props;

    const cards = (
      <Row>
        <Col
          xs={22}
          className="mx-auto"
        >
          {
banners && (banners.data).map(
  (banner) => (
    <Container
      key={banner.brandId}
      className="mt-3 p-3 bg-white border border-gray border-radius-16"
    >
      <Row
        className="mx-0 mt-3 p-2 border border-primary-light border-radius-8"
      >
        <Col
          xs={24}
          className=""
        >
          {(() => {
            let bannerJSX = null;
            switch (bannerType) {
              case 'BRAND_SPACE':
                bannerJSX = (
                  <BrandSpace
                    banner={banner}
                    onClickEdit={this.onClickEdit}
                  />
                );
                break;

              case 'MAIN_BANNER':
              case 'LL_BANNER':
                bannerJSX = (
                  <LoveLocalBanner
                    banner={banner}
                    onClickEdit={this.onClickEdit}
                  />
                );
                break;

              case 'PROMOTIONAL_BANNER':
                bannerJSX = (
                  <PromotionalBanner
                    banner={banner}
                    onClickEdit={this.onClickEdit}
                  />
                );
                break;

              case 'OTHER_LL_BANNER':
                bannerJSX = (
                  <OtherLoveLocalBanner
                    banner={banner}
                    onClickEdit={this.onClickEdit}
                  />
                );
                break;
              default:
                break;
            }
            return bannerJSX;
          })()}
        </Col>
      </Row>
    </Container>
  ),
)
}
        </Col>
      </Row>
    );

    return (
      <Container
        fluid
        className="h-100 bg-light"
      >
        {(() => {
          let addAndEditBannerJSX = null;
          switch (bannerType) {
            case 'BRAND_SPACE':
              addAndEditBannerJSX = (
                <BrandSpaceCnE
                  showEditModal={!!addEditBannerType}
                  addEditBannerType={addEditBannerType}
                  submitType={submitType}
                  selectedBannerData={selectedBannerData}
                  afterPatchAndPostSyncLocal={this.afterPatchAndPostSyncLocal}
                  onClose={() => {
                    this.setState({
                      addEditBannerType: '',
                      selectedBannerData: {},
                      submitType: 'ADD',
                    });
                  }}
                />
              );
              break;

            case 'LL_BANNER':
              addAndEditBannerJSX = (
                <LoveLocalBannerCnE
                  showEditModal={!!addEditBannerType}
                  addEditBannerType={addEditBannerType}
                  submitType={submitType}
                  selectedBannerData={selectedBannerData}
                  afterPatchAndPostSyncLocal={this.afterPatchAndPostSyncLocal}
                  onClose={() => {
                    this.setState({
                      addEditBannerType: '',
                      selectedBannerData: {},
                      submitType: 'ADD',
                    });
                  }}
                />
              );
              break;

            case 'PROMOTIONAL_BANNER':
              addAndEditBannerJSX = (
                <PromotionalBannerCnE
                  showEditModal={!!addEditBannerType}
                  addEditBannerType={addEditBannerType}
                  submitType={submitType}
                  selectedBannerData={selectedBannerData}
                  afterPatchAndPostSyncLocal={this.afterPatchAndPostSyncLocal}
                  onClose={() => {
                    this.setState({
                      addEditBannerType: '',
                      selectedBannerData: {},
                      submitType: 'ADD',
                    });
                  }}
                />
              );
              break;

            case 'OTHER_LL_BANNER':
              addAndEditBannerJSX = (
                <OtherLoveLocalBannerCnE
                  showEditModal={!!addEditBannerType}
                  addEditBannerType={addEditBannerType}
                  submitType={submitType}
                  selectedBannerData={selectedBannerData}
                  afterPatchAndPostSyncLocal={this.afterPatchAndPostSyncLocal}
                  onClose={() => {
                    this.setState({
                      addEditBannerType: '',
                      selectedBannerData: {},
                      submitType: 'ADD',
                    });
                  }}
                />
              );
              break;

            case 'MAIN_BANNER':
              addAndEditBannerJSX = (
                <MainBannerCnE
                  showEditModal={!!addEditBannerType}
                  addEditBannerType={addEditBannerType}
                  submitType={submitType}
                  selectedBannerData={selectedBannerData}
                  afterPatchAndPostSyncLocal={this.afterPatchAndPostSyncLocal}
                  onClose={() => {
                    this.setState({
                      addEditBannerType: '',
                      selectedBannerData: {},
                      submitType: 'ADD',
                    });
                  }}
                />
              );
              break;

            default:
              break;
          }
          return addAndEditBannerJSX;
        })()}
        <Row
          className="bg-white pt-2 h-50p"
        >
          <Col>
            <select
              name="bannerType"
              id="banner-type"
              value={bannerType}
              className="form-control"
              onChange={(e) => {
                history.push(`/brand-banners?t=${e.target.value}`);
                this.setState({
                  bannerType: e.target.value,
                  loading: true,
                  error: false,
                  banners: null,
                }, () => {
                  this.handleLoad();
                });
              }}
            >
              {
                bannerTypes.map((item) => (
                  <option
                    key={item}
                    value={item}
                  >
                    {item}
                  </option>
                ))
              }
            </select>
          </Col>
          <Col
            xs="auto"
          >
            <Button
              variant="primary"
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                this.setState({
                  addEditBannerType: bannerType,
                  selectedBannerData: {},
                  submitType: 'ADD',
                });
              }}
            >
              <Svg
                svg="add"
                width="24px"
                height="24px"
              />
              &nbsp;
              <b>
                ADD BANNER
              </b>
            </Button>
          </Col>
        </Row>
        <Row
          onScroll={this.handleLazyLoad}
          className="feed-container mt-0"
        >
          <Container
            fluid
            className="pb-3"
          >
            <Row>
              {banners && (
                <Col>
                  {cards}
                </Col>
              )}
            </Row>
            <Row className="text-center">
              {banners && banners.count === 0 && (
                <h6
                  className="text-secondary p-4 text-center"
                >
                  No matching Records
                </h6>
              )}
            </Row>
            <Row
              className={loading ? 'justify-content-center py-4' : 'd-none'}
            >
              <Col
                xs="auto"
                className="spinner-border text-primary p-0"
                role="status"
              >
                <span
                  className="sr-only"
                >
                  Loading...
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                className={error ? 'justify-content-center py-4' : 'd-none'}
              >
                <ErrorHandler
                  retryLogic={() => {
                    this.setState({
                      loading: true,
                      error: false,
                    }, () => {
                      this.handleLoad();
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

BrandBanners.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default BrandBanners;
