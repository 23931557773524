const priviledge = {
  SIGNIN: 'SIGNIN',
  DASHBOARD: 'DASHBOARD',
  RETAILER: 'RETAILER',
  RETAILER_DETAILS: 'RETAILER_DETAILS',
  DELIVERY_SETTING: 'DELIVERY_SETTING',
  SHOP_ALLOCATION: 'SHOP_ALLOCATION',
  ORDERS: 'ORDERS',
  PLACE_ORDER: 'PLACE_ORDER',
  CREATE_OFFER: 'CREATE_OFFER',
  ORDER_DETAILS: 'ORDER_DETAILS',
  RETAILER_ORDER_DETAILS: 'RETAILER_ORDER_DETAILS',
  BULK_COUPON_CREATION: 'BULK_COUPON_CREATION',
  SHOP_REVIEW: 'SHOP_REVIEW',
  CUSTOMERS_REVIEW: 'CUSTOMERS_REVIEW',
  REVIEW_PANEL: 'REVIEW_PANEL',
  BANNERS: 'BANNERS',
  LL_DAILY: 'LL_DAILY',
  SHARE_OFFER_CARD: 'SHARE OFFER CARD',
  SMS_SENT: 'SMS SENT',
  PENDING_SETTLEMENTS: 'PENDING_SETTLEMENTS',
  RECONCILED_SETTLEMENTS: 'RECONCILED_SETTLEMENTS',
  PENDING_SETTLEMENTS_STORE: 'PENDING_SETTLEMENTS_STORE',
  FEEDS: 'FEEDS',
  GENERIC_BULK_UPLOAD: 'GENERIC_BULK_UPLOAD',
  COUPONS: 'COUPONS',
  COUPON_TRACKER: 'COUPON_TRACKER',
  COUPON_DETAILS: 'COUPON_DETAILS',
  REFERRAL_CHECK: 'REFERRAL_CHECK',
  PRICE_UPDATE: 'PRICE_UPDATE',
  PRODUCT_ADD: 'PRODUCT_ADD',
  STORE_WISE_JOB_STATUS: 'STORE_WISE_JOB_STATUS',
  SHOP_LOGO_REVIEW: 'SHOP_LOGO_REVIEW',
  FLASH_SALE_DETAILED: 'FLASH_SALE_DETAILED',
  FLASH_SALE_AGGREGATED: 'FLASH_SALE_AGGREGATED',
  RAZORPAYX_BALANCE_STATUS: 'RAZORPAYX_BALANCE_STATUS',
  STORIES: 'STORIES',
  CUSTOMER: 'CUSTOMER',
  PRODUCT_BULK_UPLOAD: 'PRODUCT_BULK_UPLOAD',
  RETAILER_STORY_REVIEW: 'RETAILER_STORY_REVIEW',
  REFUND_DASHBOARD: 'REFUND_DASHBOARD',
  QUERY_TRACKER: 'QUERY_TRACKER',
  SMART_CATALOGUES: 'SMART_CATALOGUES',
  SMART_CATALOGUE_MAPPINGS: 'SMART_CATALOGUE_MAPPINGS',
  LIBRARY_PRODUCTS: 'LIBRARY_PRODUCTS',
  LIBRARY_PRODUCT_REGIONAL_PRICE: 'LIBRARY_PRODUCT_REGIONAL_PRICE',
  SMART_CATALOGUES_STORE: 'SMART_CATALOGUES_STORE',
  BACKGROUND_JOBS: 'BACKGROUND_JOBS',
  LEAD_MANAGEMENT: 'LEAD_MANAGEMENT',
  EXPORT_CLEANUP_TOOL: 'EXPORT_CLEANUP_TOOL',
  SHOP_CATALOGUE_PAGE: 'SHOP_CATALOGUE_PAGE',
  EXPORT_CATALOG_PRODUCTS: 'EXPORT_CATALOG_PRODUCTS',
  CUSTOMER_PANEL: 'CUSTOMER_PANEL',
  FRAUD_MANAGEMENT: 'FRAUD_MANAGEMENT',
  OM_STATUS_TRACKER: 'OM_STATUS_TRACKER',
  STORE_RESURRECTION: 'STORE_RESURRECTION',
  LEADERSHIP_BOARD: 'LEADERSHIP_BOARD',
  CAMPAIGN_ANALYTICS: 'CAMPAIGN_ANALYTICS',
  CART_ABANDON_USER: 'CART_ABANDON_USER',
  CART_ABANDON_DETAILS: 'CART_ABANDON_DETAILS',
  COUPON_ENGINE: 'COUPON_ENGINE',
  CAMPAIGN_DETAILS: 'CAMPAIGN_DETAILS',
  RETAILER_CATALOGUE_FILES: 'RETAILER_CATALOGUE_FILES',
  CX_RX_CALL_MAPPING: 'CX_RX_CALL_MAPPING',
  BRAND_SAMPLING_CAMPAIGN: 'BRAND_SAMPLING_CAMPAIGN',
  BRAND_SAMPLING_CAMPAIGN_DETAILS: 'BRAND_SAMPLING_CAMPAIGN_DETAILS',
  MARKETPLACE_BRANDS: 'MARKETPLACE_BRANDS',
  MARKETPLACE_BRAND_DETAILS: 'MARKETPLACE_BRAND_DETAILS',
  MARKETPLACE_ORDERS: 'MARKETPLACE_ORDERS',
  MARKETPLACE_ORDER_DETAILS: 'MARKETPLACE_ORDER_DETAILS',
  MARKETPLACE_BRAND_PRODUCTS: 'MARKETPLACE_BRAND_PRODUCTS',
  BRAND_BANNERS: 'BRAND_BANNERS',
  FESTIVE_CATEGORIES: 'FESTIVE_CATEGORIES',
  FINANCE_SETTLEMENT_DASHBOARD: 'FINANCE_SETTLEMENT_DASHBOARD',
  PLATFORM_FEE_DASHBOARD: 'PLATFORM_FEE_DASHBOARD',
};

export default priviledge;
