import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Container, Row, Col, Button,
} from 'react-bootstrap';
import '../../assets/scss/common/CustomTable.scss';
import Svg from './Svg';
import { Constant } from '../../utilities';

function getValidated(value) {
  const numVal = Number(value);
  if (value === '' || (numVal && numVal > 0)) {
    return true;
  }
  return false;
}

function getCellData(header, row, index) {
  if (header.renderer) {
    return header.renderer(row, index);
  }
  return row[header.key];
}

function CustomTable(props) {
  const {
    headers,
    content,
    keyField,
    border,
    hover,
    responsive,
    size,
    striped,
    l,
    p,
    rowsPerPage,
    page,
    totalItems,
    hasPrev,
    hasNext,
    onNext,
    onPrev,
    isPaginated,
    showHeader,
    updateRowsPageInput,
    onSubmitPage,
    onSubmitRowsPerPage,
    pagiationPostion,
  } = props;

  const pages = Math.ceil(totalItems / l);

  const tableHeader = (
    <thead className="p">
      <tr>
        {
         headers.map((header) => (
           <th
             key={header.key}
             className="fs-01 text-medium"
           >
             {header.displayText}
           </th>
         ))
        }
      </tr>
    </thead>
  );
  const tableBody = (
    <tbody>
      { content.map((row, rIndex) => (
        <tr
          key={row[keyField]}
        >
          {headers.map((header, index) => {
            const keyEn = index + 1;
            return (
              <td
                key={`${row[keyField]}${row[header.key]}${keyEn}`}
                className="fs-01"
              >
                {getCellData(header, row, rIndex)}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );

  return (
    <Container
      fluid
      className="px-0"
      id="custom-table"
    >
      <Row
        className="mx-0 h-100"
      >
        <Col
          xs={24}
          className="px-2 pb-4 table-container"
        >
          <div className="fixTableHead overflow-x-scroll">
            <Table
              bordered={border}
              hover={hover}
              responsive={responsive}
              size={size}
              striped={striped}
            >
              {showHeader ? tableHeader : null}
              {tableBody}
            </Table>
          </div>
        </Col>
        {!totalItems && (
          <Col
            xs={24}
            className="fs-0 text-medium px-2"
          >
            No matching results
          </Col>
        )}
        {isPaginated && (
        <Col
          xs={24}
          className={`px-0 py-1 table-pagination bg-white ${pagiationPostion}`}
        >
          <Row
            className="mx-0 align-items-center justify-content-between justify-content-md-end"
          >
            <Col
              xs="auto"
              className="px-2 py-1 d-flex align-items-center"
            >
              <div
                className="fs-01 mr-2"
              >
                Rows Per Page
              </div>
              <div>
                <input
                  type="text"
                  value={rowsPerPage || ''}
                  className="fs-01 px-1"
                  size={rowsPerPage.toString().length || 1}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (getValidated(value)) {
                      updateRowsPageInput(Number(value), 'rowsPerPage');
                    }
                  }}
                  onKeyPress={(e) => {
                    if ((e.keyCode || e.which) === 13) {
                      onSubmitRowsPerPage();
                    }
                  }}
                />
              </div>
            </Col>
            <Col
              xs="auto"
              className="px-2 py-1 d-flex align-items-center"
            >
              <div
                className="fs-01 mr-2"
              >
                Page
              </div>
              <div>
                <input
                  type="text"
                  value={page || ''}
                  className={`fs-01 px-1${totalItems && p > pages ? ' border-danger' : ''}`}
                  size={page.toString().length || 1}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (getValidated(value)
                    && (Number(value) < pages + 1)) {
                      updateRowsPageInput(Number(value), 'page');
                    }
                  }}
                  onKeyPress={(e) => {
                    if ((e.keyCode || e.which) === 13) {
                      onSubmitPage();
                    }
                  }}
                />
              </div>
            </Col>
            <Col
              xs="auto"
              className="px-2 fs-01"
            >
              {`${
                p > pages
                  ? '0 - 0'
                  : `${
                    (p - 1) * l + 1
                  } - ${
                    totalItems < (l * p)
                      ? totalItems : (l * p)
                  }`
              } of ${totalItems}`}
            </Col>
            <Col
              xs="auto"
              className="px-2 py-1 text-center"
            >
              <Button
                variant="link"
                className="py-0 px-1 border bg-light"
                onClick={onPrev}
                disabled={!hasPrev}
              >
                <Svg
                  svg="prev"
                  width="1.2rem"
                  stroke={hasPrev
                    ? Constant.Color.DARK
                    : Constant.Color.SECONDARY}
                />
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="link"
                className="py-0 px-1 border bg-light"
                onClick={onNext}
                disabled={!hasNext}
              >
                <Svg
                  svg="next"
                  width="1.2rem"
                  stroke={hasNext
                    ? Constant.Color.DARK
                    : Constant.Color.SECONDARY}
                />
              </Button>
            </Col>
          </Row>
        </Col>
        )}
      </Row>
    </Container>
  );
}

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})),
  keyField: PropTypes.string.isRequired,
  border: PropTypes.bool,
  hover: PropTypes.bool,
  responsive: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  size: PropTypes.string,
  striped: PropTypes.bool,
  totalItems: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  updateRowsPageInput: PropTypes.func,
  hasPrev: PropTypes.bool,
  hasNext: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  isPaginated: PropTypes.bool,
  showHeader: PropTypes.bool,
  onSubmitPage: PropTypes.func,
  onSubmitRowsPerPage: PropTypes.func,
  l: PropTypes.number,
  p: PropTypes.number,
  pagiationPostion: PropTypes.string,
};

CustomTable.defaultProps = {
  content: [],
  border: false,
  hover: true,
  responsive: true,
  size: 'sm',
  striped: false,
  totalItems: 0,
  rowsPerPage: 0,
  page: 0,
  hasPrev: false,
  hasNext: false,
  isPaginated: true,
  showHeader: true,
  pagiationPostion: '',
  updateRowsPageInput: () => {},
  onNext: () => {},
  onPrev: () => {},
  onSubmitPage: () => {},
  onSubmitRowsPerPage: () => {},
  l: 0,
  p: 0,
};

export default CustomTable;