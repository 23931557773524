import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import { CustomTable, ErrorHandler, Svg } from '../../../../component/common';
import { Constant } from '../../../../utilities';
import { addAlternateStoreProduct, retailerRequest } from '../../../../assets/api/axios';
import { getDefaultImageUrl } from '../../../../utilities/Utils';

function AddLibraryProduct(props) {
  const {
    onCancel, orderId, storeId, storeItems, loadProducts,
    addNewProductReq, newlyAddedProducts, handleAddNewProduct,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [l, setL] = useState(10);
  const [p, setP] = useState(1);
  const [performingAction, setPerformingAction] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [addProductErrorMsg, setAddProductErrorMsg] = useState('');

  const handleLoad = (params = {}) => {
    setLoadingStatus('loading');
    const newParams = { page, rowsPerPage, ...params };
    addAlternateStoreProduct(
      'GET',
      {
        page: newParams.page,
        limit: l,
      },
      storeId,
      searchText,
    ).then((res) => {
      setPage(newParams.page);
      setRowsPerPage(newParams.rowsPerPage);
      setP(newParams.page);
      setL(newParams.rowsPerPage);
      setSearchResult(res.data);
      setLoadingStatus('success');
    }).catch(() => {
      setLoadingStatus('error');
    });
  };

  function checkSearchProductExistence(list, element) {
    let flag = false;
    list.forEach((item) => {
      if (item?.LibraryProductId === element?.libraryProductId) { flag = true; }
    });
    return flag;
  }

  function patchAddProductFromLibrary(product, totalProduct) {
    retailerRequest(
      'POST',
      {
        storeId,
        productIds: [product.libraryProductId],
        orderLibraryProductId: totalProduct.LibraryProductId,
        retailerProductId: product.retailerProductId,
        orderId,
      },
    ).then(() => {
      setPerformingAction('');
      setSelectedProductId('');
      onCancel();
      loadProducts();
    }).catch(() => {
      setAddProductErrorMsg('OOPS Retry');
    });
  }

  function handleAddProductFromLibrary(product, totalProduct, action) {
    setSelectedProductId(product.libraryProductId);
    setPerformingAction(action);
    setAddProductErrorMsg('');
    patchAddProductFromLibrary(product, totalProduct);
  }

  function renderStatus(status) {
    return status
      ? <span className="text-success">&#10004;</span>
      : <span className="text-danger">&#10006;</span>;
  }

  const onNext = () => {
    handleLoad({ page: page + 1 });
  };

  const onPrev = () => {
    handleLoad({ page: page - 1 });
  };

  const onSubmitRowsPerPage = () => {
    handleLoad();
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'rowsPerPage') { setRowsPerPage(value); } else { setPage(value); }
  };

  const onSubmitPage = () => {
    handleLoad();
  };

  const searchProductHeaders = [
    {
      key: 'name',
      displayText: 'Product Name',
    },
    {
      key: 'mrp',
      displayText: 'MRP',
    },
    {
      key: 'sellingPrice',
      displayText: 'SP',
      renderer: (product) => <span>{product.inInventory ? product.sellingPrice : ''}</span>,
    },
    {
      key: 'image',
      displayText: 'Image',
      renderer: (product) => (
        <img
          alt=""
          src={product.image ? product.image : getDefaultImageUrl()}
          width="50px"
          height="50px"
        />
      ),
    },
    {
      key: 'isInventory',
      displayText: 'In Inventory',
      renderer: (product) => renderStatus(product.inInventory),
    },
    {
      key: 'select',
      displayText: 'Add Product',
      renderer: (product) => {
        if (
          performingAction === 'add_product'
          && selectedProductId === product.libraryProductId
          && !addProductErrorMsg
        ) {
          return (
            <div
              className="pt-1 d-flex align-item-center
              justify-content-center"
            >
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            </div>
          );
        }
        if (product.inInventory) {
          return (
            <>
              <Button
                variant="link"
                disabled={
                  addNewProductReq
                    ? checkSearchProductExistence(newlyAddedProducts, product)
                    : checkSearchProductExistence(storeItems.matchingProducts, product)
                }
                onClick={() => {
                  if (addNewProductReq) {
                    handleAddNewProduct(product);
                    onCancel();
                  } else {
                    handleAddProductFromLibrary(product, storeItems.orderProduct, 'add_product');
                  }
                }}
              >
                {(() => {
                  if (addNewProductReq) {
                    return checkSearchProductExistence(newlyAddedProducts, product) ? 'Added' : 'Select';
                  }
                  return checkSearchProductExistence(storeItems.matchingProducts, product) ? 'Added' : 'Select';
                })()}
              </Button>
              {
                addProductErrorMsg && selectedProductId === product.libraryProductId && (
                  <span className="text-danger">Retry</span>
                )
              }
            </>
          );
        }
        return (
          <>
            <Button
              variant="link"
              disabled={
                addNewProductReq
                  ? checkSearchProductExistence(newlyAddedProducts, product)
                  : checkSearchProductExistence(storeItems.matchingProducts, product)
              }
              onClick={() => {
                if (addNewProductReq) {
                  handleAddNewProduct(product);
                  onCancel();
                } else {
                  handleAddProductFromLibrary(product, storeItems.orderProduct, 'add_product');
                }
              }}
            >
              {(() => {
                if (addNewProductReq) {
                  return checkSearchProductExistence(newlyAddedProducts, product) ? 'Added' : '+Add';
                }
                return checkSearchProductExistence(storeItems.matchingProducts, product) ? 'Added' : '+Add';
              })()}
            </Button>
            {
              addProductErrorMsg && selectedProductId === product.libraryProductId && (
                <span className="text-danger">Retry</span>
              )
            }
          </>
        );
      },
    },
  ];

  return (
    <Container
      fluid
    >
      <Row>
        <Col
          className="px-0"
        >
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className="rounded-0"
              >
                <Svg
                  svg="search"
                  width="1rem"
                  fill={Constant.Color.DARK}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="search"
              placeholder="search"
              autoFocus
              className="fs-1 rounded-0"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  handleLoad({ page: 1 });
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col
          xs="auto"
          className="px-0 ml-2"
        >
          <Button
            className="rounded-0"
            onClick={onCancel}
          >
            <Svg
              svg="close"
              width="1rem"
              fill={Constant.Color.WHITE}
            />
          </Button>
        </Col>
      </Row>
      <Row>
        {(() => {
          let showcase = null;
          if (loadingStatus === 'loading') {
            showcase = (
              <Col
                xs={24}
                className="w-100 pt-3 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </Col>
            );
          } else if (loadingStatus === 'error') {
            showcase = (
              <Col
                xs={24}
                className="h-100 d-flex align-items-center
                    justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => {
                    handleLoad();
                  }}
                />
              </Col>
            );
          } else if (loadingStatus === 'success') {
            showcase = (
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={searchProductHeaders}
                  content={searchResult.data}
                  keyField="id"
                  totalItems={searchResult.count}
                  isPaginated
                  rowsPerPage={rowsPerPage}
                  page={page}
                  l={l}
                  p={p}
                  hasPrev={searchResult.prev !== ''}
                  hasNext={searchResult.next !== ''}
                  onNext={onNext}
                  onPrev={onPrev}
                  onSubmitPage={onSubmitPage}
                  onSubmitRowsPerPage={onSubmitRowsPerPage}
                  updateRowsPageInput={handleRowsPageInput}
                  pagiationPostion="position-sticky"
                />
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );
}

AddLibraryProduct.propTypes = {
  onCancel: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  storeId: PropTypes.string.isRequired,
  storeItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadProducts: PropTypes.func.isRequired,
  addNewProductReq: PropTypes.bool,
  newlyAddedProducts: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddNewProduct: PropTypes.func,
};

AddLibraryProduct.defaultProps = {
  addNewProductReq: false,
  newlyAddedProducts: [],
  handleAddNewProduct: () => {},
};

export default AddLibraryProduct;
