import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { CustomModal } from '../../../../component/common';
import AddLibraryProduct from './AddLibraryProduct';

function AddProductModal(props) {
  const {
    show, onHide, storeId, orderId, storeItems, loadProducts,
    addNewProductReq, newlyAddedProducts, handleAddNewProduct,
  } = props;
  return (
    <CustomModal
      show={show}
      size="xl"
      onHide={onHide}
      body={(
        <Container>
          <Row className="mb-4 pl-4 py-2 bg-primary text-white">
            <b>{`Add Suggested Product - ${storeId}`}</b>
          </Row>
          <AddLibraryProduct
            onCancel={onHide}
            orderId={orderId}
            storeId={storeId}
            storeItems={storeItems}
            loadProducts={loadProducts}
            addNewProductReq={addNewProductReq}
            newlyAddedProducts={newlyAddedProducts}
            handleAddNewProduct={handleAddNewProduct}
          />
        </Container>
      )}
      backdrop
      border
    />
  );
}

AddProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  storeItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadProducts: PropTypes.func.isRequired,
  addNewProductReq: PropTypes.bool,
  newlyAddedProducts: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddNewProduct: PropTypes.func,
};

AddProductModal.defaultProps = {
  addNewProductReq: false,
  newlyAddedProducts: [],
  handleAddNewProduct: () => {},
};

export default AddProductModal;
