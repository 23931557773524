import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Button, Row, Spinner, Col,
} from 'react-bootstrap';
import { rerouteOrderItems } from '../../../../assets/api/axios';
import { CustomModal, CustomTable } from '../../../../component/common';
import AddProductModal from './AddProductModal';
import { useStateWithCallback, getDefaultImageUrl } from '../../../../utilities/Utils';

export default function MatchedProducts(props) {
  const {
    storeCode, orderId, onConfirmProducts, onCancel,
  } = props;
  const [products, setProducts] = useState(null);
  const [loadStatus, setLoadStatus] = useState('');
  const [storeItems, setStoreItems] = useState([]);
  const [addProductModal, setAddProductModal] = useState(false);
  const [errorNote, setErrorNote] = useState(false);
  const [patchItem, setPatchItem] = useStateWithCallback(null);
  const [, setPatchStatus] = useState('');
  const [newlyAddedProducts, setNewlyAddedProducts] = useState([]);
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [prevCustomProducts, setPrevCustomProducts] = useState([]);
  const [customProducts, setCustomProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const loadProducts = () => {
    setLoadStatus('loading');
    rerouteOrderItems(
      'GET',
      orderId,
      { storeId: storeCode },
    ).then((res) => {
      setLoadStatus('success');
      setProducts(res.data.data.orderProducts);
      // eslint-disable-next-line max-len
      setCustomProducts(res.data.data.customOrderProducts.map((product) => product.customProduct) || []);
      setPrevCustomProducts(res.data.data.customOrderProducts || []);
      setErrorNote(false);
    }).catch(() => {
      setLoadStatus('error');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      loadProducts();
    }
  });

  const selectMatchProduct = (product, index) => {
    setProducts(products.map((item) => {
      if (item.orderProduct.LibraryProductId === product.originalProductId) {
        const itemCopy = item;
        itemCopy.isMatched = true;
        itemCopy.matchingProducts.forEach((element, i) => {
          itemCopy.matchingProducts[i].isMatched = false;
        });
        itemCopy.matchingProducts[index - 1].isMatched = true;
        return itemCopy;
      }
      return item;
    }));
  };

  const handleAddNewProduct = (newProduct) => {
    setNewlyAddedProducts([
      ...newlyAddedProducts,
      {
        imageUrl: newProduct.image,
        name: newProduct.name,
        productId: newProduct.id,
        quantity: 1,
        mrp: newProduct.mrp,
        sellingPrice: newProduct.sellingPrice || newProduct.mrp,
        storeProductId: newProduct.id,
        unit: newProduct.unit,
        uom: newProduct.uom,
        isSuggested: true,
        isNewlyAdded: true,
      },
    ]);
  };

  const handleFieldUpdate = (updatedItem, parentProductId, index) => {
    const selectOriginalProducts = products?.find(
      (item) => item.LibraryProductId === parentProductId,
    );
    const updateMatchProduct = selectOriginalProducts.matchingProducts[index - 1];
    updateMatchProduct[updatedItem.field] = updatedItem.value;
    setProducts(products?.forEach((item) => {
      if (item.orderProduct.LibraryProductId === parentProductId) {
        return { ...selectOriginalProducts };
      }
      return { ...item };
    }));
    setPatchItem(null);
  };

  const handleNewProductFieldUpdate = (field, value, index) => {
    const updatedProducts = newlyAddedProducts;
    updatedProducts[index][field] = value;
    setNewlyAddedProducts(updatedProducts);
    setPatchItem(null);
    setPatchStatus('');
  };

  const handleCustomProductFieldUpdate = (field, value, index) => {
    const updatedProducts = customProducts;
    updatedProducts[index][field] = value;
    setCustomProducts(updatedProducts);
    setPatchItem(null);
    setPatchStatus('');
  };

  const handleDeleteProduct = (product, index, isNewProduct = false) => {
    if (isNewProduct) {
      newlyAddedProducts.splice(index, 1);
    } else if (product.isCustomProduct) {
      // eslint-disable-next-line max-len
      const updatedCustomProducts = customProducts.filter((item) => item.customOrderProductId !== product.customOrderProductId);
      setCustomProducts(updatedCustomProducts);
    } else {
      const updatedProducts = products?.filter(
        (item) => item.orderProduct.LibraryProductId !== product.LibraryProductId,
      );
      setProducts(updatedProducts);
    }
    setSelectedProduct(null);
  };

  const editFieldShowCase = (data, field, index, isNewProduct = false) => {
    if (patchItem && (patchItem.productId === data.productId)
    && (patchItem.storeProductId === data.storeProductId)
    && (patchItem.field === field)) {
      let isValid = false;
      if (patchItem.value) {
        if (field === 'sellingPrice' && patchItem.value <= data.mrp) {
          isValid = true;
        }
        if (field === 'sellingPrice' && data.isCustomProduct) {
          isValid = true;
        }
        if (field === 'quantity' && patchItem.value <= 10) {
          isValid = true;
        }
        if (field === 'unit' || field === 'uom') {
          isValid = true;
        }
      }
      return (
        <input
          type="text"
          value={patchItem.value}
          size={patchItem.value.toString().length || 1}
          onChange={(e) => {
            const { value } = e.target;
            const numVal = Number(value);
            if (value === '' || (numVal && numVal > 0)) {
              setPatchItem({
                productId: data.productId,
                storeProductId: data.storeProductId,
                field,
                value: numVal || '',
              });
            }
          }}
          onKeyPress={(e) => {
            if (e.which === 13 && isValid
            && patchItem.value !== data[field]) {
              if (isNewProduct) {
                handleNewProductFieldUpdate(field, patchItem.value, index);
              } else if (data.isCustomProduct) {
                handleCustomProductFieldUpdate(field, patchItem.value, index);
              } else {
                handleFieldUpdate(patchItem, data.parentId, index);
              }
            }
          }}
          onBlur={() => {
            setPatchItem(null);
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={`fs-01 text-medium ${isValid
            ? '' : 'border-danger'}`}
        />
      );
    }
    return (
      <Button
        variant="link"
        className="fs-01 text-medium p-0"
        onClick={() => {
          setPatchItem({
            productId: data.productId,
            storeProductId: data.storeProductId,
            field,
            value: data[field],
          });
        }}
      >
        {data[field] || '--'}
      </Button>
    );
  };

  const productHeader = [
    {
      key: 'select',
      displayText: 'Select Matched Product',
      renderer: (data, index) => (data.isNewlyAdded || data.isCustomProduct
        ? (
          '--'
        ) : (
          <>
            {(() => {
              if (data.isSuggested && data.isMatched) {
                return (<div className="bg-green text-white text-center py-2">SUGGESTION MATCHED</div>);
              }
              if (!data.isSuggested) {
                return (
                  <div className="font-weight-bold text-black">
                    Original Product
                  </div>
                );
              }
              return (
                <input
                  type="radio"
                  name={data}
                  checked={data.isMatched}
                  onChange={() => {
                    selectMatchProduct(data, index);
                    setErrorNote(false);
                  }}
                />
              );
            })()}
          </>
        )
      ),
    },
    {
      key: 'LibraryProductId',
      displayText: 'Id',
      renderer: (data) => (
        <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
          {data.isCustomProduct ? data.customOrderProductId : data.LibraryProductId}
        </span>
      ),
    },
    {
      key: 'name',
      displayText: 'Name',
      renderer: (data) => (
        <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
          {data.name}
        </span>
      ),
    },
    {
      key: 'imageUrl',
      displayText: 'Image',
      renderer: (data) => (
        <div
          className="product-image-div"
        >
          <img
            src={data.imageUrl ? data.imageUrl : getDefaultImageUrl()}
            alt=""
            className="w-100 h-100"
          />
        </div>
      ),
    },
    {
      key: 'unit',
      displayText: 'Unit',
      renderer: (data) => (
        <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
          {data.unit}
        </span>
      ),
    },
    {
      key: 'uom',
      displayText: 'UOM',
      renderer: (data) => (
        <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
          {data.uom}
        </span>
      ),
    },
    {
      key: 'sellingPrice',
      displayText: 'SP.',
      renderer: (data, index) => (!data.isSuggested
        ? (
          <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
            {data.sellingPrice}
          </span>
        ) : editFieldShowCase(data, 'sellingPrice', index, data.isNewlyAdded)
      ),
    },
    {
      key: 'quantity',
      displayText: 'QTY.',
      renderer: (data, index) => (!data.isSuggested
        ? (
          <span className={`${!data.isSuggested ? 'text-black font-weight-bold' : ''}`}>
            {data.quantity}
          </span>
        ) : editFieldShowCase(data, 'quantity', index, data.isNewlyAdded)
      ),
    },
    {
      key: 'action',
      displayText: 'Action',
      renderer: (data, index) => ((data.isSuggested && !data.isNewlyAdded && !data.isCustomProduct)
        ? (
          '--'
        ) : (
          <Button
            onClick={() => {
              setSelectedProduct({ ...data, index });
            }}
            className="fs-0 py-1"
          >
            Remove
          </Button>
        )
      ),
    },
  ];

  const getProductContent = (product) => {
    const matches = product.matchingProducts;
    return ([
      { ...product.orderProduct, isSuggested: false },
      ...matches.map((item) => ({
        ...item,
        isMatched: item.isMatched,
        isSuggested: true,
        originalProductId: product.orderProduct?.LibraryProductId,
        parentId: product.orderProduct?.RetailerProductId,
      })),
    ]);
  };

  const getProductLayout = () => products?.map((product) => (
    <Row className="product-section" key={product.orderProduct.LibraryProductId}>
      <CustomTable
        headers={productHeader}
        content={getProductContent(product)}
        isPaginated={false}
        keyField="productId"
        totalItems={product.matchingProducts.length + 1}
      />
      <Col className="mt-n5">
        <Button
          variant="outline-primary"
          onClick={() => {
            setAddProductModal(true);
            setStoreItems(product);
            setErrorNote(false);
          }}
          className="fs-0"
        >
          SUGGEST ALTERNATIVE PRODUCT
        </Button>
      </Col>
    </Row>
  ));

  if (loadStatus === 'loading') {
    return (
      <div
        className="h-100 d-flex align-items-center
            justify-content-center"
      >
        <Spinner
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (loadStatus === 'error') {
    return (
      <div
        className="justify-content-center
        align-items-center text-danger d-flex"
      >
        Oops! Error while fetching stores.
        <Button
          variant="link"
          onClick={() => { setLoadStatus(''); }}
        >
          <b>
            Retry
          </b>
        </Button>
      </div>
    );
  }

  const deleteProductBody = (
    <Container className="px-5 py-4">
      <Row className="justify-content-center">
        <b>Are you sure you want to remove this product?</b>
      </Row>
      <Row className="justify-content-center py-2">
        <Button
          onClick={() => {
            setSelectedProduct(null);
          }}
          className="mx-1"
        >
          NO
        </Button>
        <Button
          variant="danger"
          className="mx-1"
          onClick={() => {
            handleDeleteProduct(
              selectedProduct, selectedProduct.index, selectedProduct.isNewlyAdded,
            );
          }}
        >
          YES
        </Button>
      </Row>
    </Container>
  );

  const selectedMatchProduct = products?.filter((product) => product.isMatched);

  return (
    <Container className="bg-light px-0">
      {
        selectedProduct && (
          <CustomModal
            show={!!selectedProduct}
            onHide={() => {
              setSelectedProduct(null);
            }}
            title="Delete Product"
            body={deleteProductBody}
            autoSize
            closeButton
            border
          />
        )
      }
      {
        addProductModal && (
          <AddProductModal
            show={addProductModal}
            onHide={() => {
              setAddProductModal(false);
              setAddNewProduct(false);
            }}
            orderId={orderId}
            storeId={storeCode}
            storeItems={storeItems}
            loadProducts={loadProducts}
            addNewProductReq={addNewProduct}
            newlyAddedProducts={newlyAddedProducts}
            handleAddNewProduct={handleAddNewProduct}
          />
        )
      }
      <Row className="mx-0 bg-white pb-2 d-flex align-items-center">
        <Col className="font-italic px-0">
          Select suggested matches for existing order items:
        </Col>
        <Col className="px-0 d-flex justify-content-end">
          {/* <Button
            onClick={() => {
              setAddProductModal(true);
              setAddNewProduct(true);
              setErrorNote(false);
            }}
            disabled
          >
            Add New Product
          </Button> */}
        </Col>
      </Row>
      {
        products?.length > 0 && (
          getProductLayout()
        )
      }
      {
        customProducts && customProducts.length > 0 && (
          <>
            <Row className="mx-0 bg-white pb-2 pt-4 d-flex align-items-center">
              <Col className="font-italic px-0 font-weight-bold">
                Custom Products in the order:
              </Col>
            </Row>
            <Row className="product-section">
              <CustomTable
                headers={productHeader}
                content={customProducts}
                isPaginated={false}
                keyField="productId"
                totalItems={customProducts.length}
              />
            </Row>
          </>
        )
      }
      {
        newlyAddedProducts && newlyAddedProducts.length > 0 && (
          <>
            <Row className="mx-0 bg-white pb-2 pt-4 d-flex align-items-center">
              <Col className="font-italic px-0 font-weight-bold">
                Newly Added Product in the order:
              </Col>
            </Row>
            <Row className="product-section">
              <CustomTable
                headers={productHeader}
                content={newlyAddedProducts}
                isPaginated={false}
                keyField="productId"
                totalItems={newlyAddedProducts.length}
              />
            </Row>
          </>
        )
      }
      {
        errorNote && (
          <Row className="bg-white d-flex justify-content-center pt-2">
            <span className="text-danger">
              Please select atleast one suggested product or add new product
            </span>
          </Row>
        )
      }
      {
        <Row className="bg-white d-flex justify-content-center py-4">
          <Button
            variant="primary"
            className="mx-2"
            onClick={() => {
              if (
                selectedMatchProduct.length < 1 && newlyAddedProducts.length < 1
                && customProducts.length < 1
              ) {
                setErrorNote(true);
              } else {
                // eslint-disable-next-line max-len
                onConfirmProducts(products, customProducts, newlyAddedProducts, prevCustomProducts);
              }
            }}
          >
            CONFIRM
          </Button>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={onCancel}
          >
            BACK
          </Button>
        </Row>
      }
    </Container>
  );
}

MatchedProducts.propTypes = {
  storeCode: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  onConfirmProducts: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
